/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Progress, message, Steps, Checkbox, Radio, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import web3 from "web3";
import Action from "./action";
import { useContract } from "../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS } from "../../constants";
import abiBSCS from "../../abi/abiBSCS.json";

import { SC_STAKE_BSCS } from "../../constants";
import abiStake from "./utils/abiStake.json"
import { isMobile } from "react-device-detect";
import { useHookStaked } from './storeStaked'
import { useAccount, useChainId } from "wagmi";


const steps = [
    {
        title: '1',
        content: 'First-content',
    },
    {
        title: '2',
        content: '2-content',
    },
    {
        title: '3',
        content: '3-content',
    },
    {
        title: '4',
        content: '4-content',
    },
    {
        title: '5',
        content: 'Last-content',
    },
];

const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
declare const window: Window & typeof globalThis & { ethereum: any };
const Stake = (props: any) => {
    const { address } = useAccount();
    const chainId = useChainId()
    let account = address
    const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);
    const stakeContract: any = useContract(SC_STAKE_BSCS, abiStake)
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [bnbBalance, setBnbBalance] = useState(0);
    const [state, actions] = useHookStaked()

    const [isSuccess, setIsSuccess]: any = useState(false)
    const [amountStake, setAmountStake]: any = useState()
    const [current, setCurrent] = useState(0);
    const [amountApproved, setAmountApproved]: any = useState(0);
    const [amountBscs, setAmountBscs]: any = useState(0);
    const [isCheck, setIsCheck] = useState(false);
    const [isCheckRule, setIsCheckRule] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [hashStake, setHashStake]: any = useState('');
    const [unstakeAmount, setUnstakeAmount] = useState(0);
    const [childData, setchildData]: any = useState(false)


    const formatNumber = (x: any, max: any) => {
        let tem = x * 10000000
        let tem1 = Math.floor(tem)
        let tem2 = tem1 / 10000000
        if (tem2) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };

    const { Step } = Steps;
    useEffect(() => {
        getAmountApprove()
    }, [account, props.tabBox, current, childData, bscsContract])

    const next = () => {
        if (current === 2) {
            if (amountApproved < amountStake) {
                handleApprove()
            } else {
                setCurrent(current + 1);
            }
        } else {
            if (current === 3) {
                handleStaking()
            } else {
                setCurrent(current + 1);
            }

        }

    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="./images/img/stake1.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="./images/img/stake2.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/img/stake2-ac.png" alt="" />
        }
    }
    const renderImg3 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="./images/img/stake3.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/img/stake3-ac.png" alt="" />
        }
    }
    const renderImg4 = () => {
        if (current < 3) {
            return <img className="img-step-stake" src="./images/img/stake4.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/img/stake4-ac.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 4) {
            return <img className="img-step-stake" src="./images/img/stake5.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/img/stake5-ac.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Checkpoints</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Amount to Stake"
        } else {
            return <span className="text-ac">Amount to Stake</span>
        }

    }

    const renderText3 = () => {
        if (current < 2) {
            return "Pre-authorization"
        } else {
            return <span className="text-ac">Pre-authorization</span>
        }

    }
    const renderText4 = () => {
        if (current < 3) {
            return "Confirm"
        } else {
            return <span className="text-ac">Confirm</span>
        }

    }
    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }
    }

    const onChange = (e: any) => {
        setIsCheck(e.target.checked)
    };
    const onChangeAll = () => {
        setIsCheck(!isCheck)
    };

    const onChangeRule = (e: any) => {
        setIsCheckRule(e.target.checked)
    }
    const onChangeRuleAll = () => {
        setIsCheckRule(!isCheckRule)
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(account);

            let bscsBL = Math.floor((Number(amount) / 1e18) * 100000) / 100000;
            setAmountBscs(Number(bscsBL));
        }
    };

    useEffect(() => {
        if (account) {
            getBscs();
        } else {
            setAmountBscs(0)
        }
    }, [account, props.tabBox, current, childData, bscsContract]);

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.floor(parseFloat(_balance) * 100000) / 100000;
                    setBnbBalance(bnbBl);
                }
            });
        } else {
            setBnbBalance(0)
        }
    }, [account, chainId, props.tabBox, current, childData]);

    const getAmountApprove = async () => {
        try {
            await bscsContract.allowance(account, SC_STAKE_BSCS).then((res: any) => {
                setAmountApproved(res / 1e18)
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountApproved(0)
                })
        } catch (error) {

        }
    }

    const handleApprove = async () => {
        try {
            if (!bscsContract) {
                message.error({
                    type: "error",
                    content: "Can't reading contract BSCS",
                    className: "custom-class",
                    duration: 3,
                });
                return;
            }
            setIsModalOpen(true);
            let amount = web3.utils.toWei(amountStake.toString(), "ether");
            await bscsContract.approve(SC_STAKE_BSCS, amount).then((res: any) => {

                res.wait()
                    .then((resWait: any) => {
                        if (resWait?.status === 1) {
                            let tem = web3.utils.fromWei(resWait?.logs[0]?.data.toString(), "ether");
                            if (Number(tem) < amountStake) {
                                message.warning({
                                    type: "warning",
                                    content: "Please approve more token for staking",
                                    className: "custom-class",
                                    duration: 4,
                                });
                                setIsModalOpen(false);
                            } else {
                                message.success({
                                    type: "success",
                                    content: "Approved successfully",
                                    className: "custom-class",
                                    duration: 3,
                                });
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                            }
                        } else {
                            message.error({
                                type: "error",
                                content: "Unknown error (status hash fail)",
                                className: "custom-class",
                                duration: 3,
                            });
                            setIsModalOpen(false);
                        }

                    })
                    .catch((errWait: any) => {
                        console.log('errWait', errWait)
                    })

            })
                .catch((err: any) => {
                    console.log('err', err)
                    setIsModalOpen(false);
                    message.error({
                        type: "error",
                        content: err?.message || "Unknown error",
                        className: "custom-class",
                        duration: 3,
                    });
                })
        } catch (error) {
            console.log('error', error)
            setIsModalOpen(false);
        }
    }




    const handleStaking = async () => {
        try {
            setIsModalOpen(true);
            if (!stakeContract) {
                setIsModalOpen(false);
                message.error({
                    type: "error",
                    content: "Stake contract not found",
                    className: "custom-class",
                    duration: 3,
                });
                return
            }
            let amount = web3.utils.toWei(amountStake.toString(), "ether");
            await stakeContract.stake(amount)
                .then(async (res: any) => {
                    // console.log('res111', res.hash)
                    const timestamp = new Date().getTime();
                    const date1 = new Date(timestamp);
                    let timeStake = date1.toLocaleString('en-GB')
                    let paramNoti = {
                        projectName: "BSCS",
                        amount: amountStake,
                        actionType: 1,
                        userAddress: account,
                        tokenName: "BSCS",
                        actionTime: timeStake,
                        hash: res.hash,
                        status: "Waiting"
                    }
                    await actions.callNoti(paramNoti)

                    let params = {
                        ownerAddress: account,
                        poolContract: SC_STAKE_BSCS
                    }

                    let resapi = await actions.updateStaked(params)
                    if (resapi.data.data) {
                        res.wait()
                            .then(async (resWait: any) => {
                                // console.log('resWait222', resWait)
                                if (resWait?.status === 1) {
                                    const timestamp = new Date().getTime();
                                    const date1 = new Date(timestamp);
                                    let timeStake = date1.toLocaleString('en-GB')
                                    let paramNoti = {
                                        projectName: "BSCS",
                                        amount: amountStake,
                                        actionType: 1,
                                        userAddress: account,
                                        tokenName: "BSCS",
                                        actionTime: timeStake,
                                        hash: resWait.transactionHash,
                                        status: "Success"
                                    }
                                    await actions.callNoti(paramNoti)
                                    setHashStake(resWait.transactionHash)
                                    message.success({
                                        type: "success",
                                        content: "Stake successfully",
                                        className: "custom-class",
                                        duration: 3,
                                    });
                                    props.setIsCheckAction(!props.isCheckAction)
                                    setIsSuccess(!isSuccess)
                                    setIsModalOpen(false);
                                    setCurrent(current + 1);


                                } else {
                                    message.error({
                                        type: "error",
                                        content: "Unknown error (status hash fail)",
                                        className: "custom-class",
                                        duration: 3,
                                    });
                                    setIsModalOpen(false);
                                }

                            })
                            .catch((err: any) => {
                                message.error({
                                    type: "error",
                                    content: "Unknown error",
                                    className: "custom-class",
                                    duration: 3,
                                });
                                setIsModalOpen(false);
                            })
                    } else {
                        message.error({
                            type: "error",
                            content: "API update status stake fail",
                            className: "custom-class",
                            duration: 3,
                        });
                        setIsModalOpen(false);
                    }


                })
                .catch((err: any) => {
                    console.log('err', err)
                    message.error({
                        type: "error",
                        content: err.reason || err?.message,
                        className: "custom-class",
                        duration: 3,
                    });
                    setIsModalOpen(false);
                })
        } catch (error: any) {
            console.log('err catch-->', error)
            message.error({
                type: "error",
                content: error,
                className: "custom-class",
                duration: 3,
            });
            setIsModalOpen(false);

        }
    }

    const checkFlag = async () => {
        try {
            await stakeContract.userMap(account).then((res: any) => {
                if (res) {
                    setUnstakeAmount(res.inUnStakeAmount / 1e18)
                } else {
                    setUnstakeAmount(0)
                }
            })
                .catch((err: any) => {
                    console.log('err', err)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (stakeContract) {
            checkFlag()
        } else {
            setUnstakeAmount(0)
        }

    }, [account, stakeContract, props.tabBox, childData])


    const handleShowNext = () => {
        if (current === 0) {
            if (account && Number(amountBscs) > 0 && bnbBalance > 0 && isCheck && unstakeAmount <= 0) {
                setShowNext(true)
            } else {
                setShowNext(false)
            }
        }
        if (current === 1) {
            if (Number(amountStake) > 0 && amountStake && Number(amountStake) <= Number(amountBscs) && isCheckRule) {
                // if (Number(amountStake) > 0 && amountStake && isCheckRule) {
                setShowNext(true)
            } else {
                setShowNext(false)
            }
        }
        if (current === 2) {

        }

    }

    useEffect(() => {
        handleShowNext()
    }, [account, amountBscs, bnbBalance, isCheck, current, amountStake, isCheckRule, unstakeAmount, props.tabBox, childData])

    const handleChangeInputStake = (event: any) => {
        let x = event.target.value.replace(/[^0-9.]/g, "");
        setAmountStake(x)

    }

    const handleAmountMax = () => {
        setAmountStake(amountBscs)
    }


    const callbackFunction = (data: any) => {
        setchildData(data)
    };

    return (
        <div className="wrap-stake">
            <div className="stake">
                <div className="wrap-all">

                    <div className="stake-your-token">Stake your BSCS</div>
                    <div className="content-stake">

                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Steps current={current}>
                            <Step
                                title={renderText1()}
                                icon={renderImg1()}
                            />
                            <Step
                                title={renderText2()}
                                icon={renderImg2()}
                            />
                            <Step
                                title={renderText3()}
                                icon={renderImg3()}
                            />
                            <Step
                                title={renderText4()}
                                icon={renderImg4()}
                            />
                            <Step
                                title={renderText5()}
                                icon={renderImg5()}
                            />
                        </Steps>


                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="title">Checkpoints</div>
                                <div className="des">The following conditions must be met to proceed:</div>
                                <div className="item-step1">
                                    <div className="card-item">
                                        <div className={`${account ? 'check-card acive' : 'check-card'}`}>
                                            <img src="./images/img/check-before.png" alt="" />
                                            <img src="./images/img/check-after.png" alt="" />
                                        </div>
                                        <div className="title-card">Connected with MetaMask</div>
                                        <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                    </div>
                                    <div className="card-item"
                                    >
                                        <div className={`${amountBscs > 0 ? 'check-card acive' : 'check-card'}`}>

                                            <img src="./images/img/check-before.png" alt="" />
                                            <img src="./images/img/check-after.png" alt="" />

                                        </div>
                                        <div className="title-card">BSCS available to deposit</div>
                                        <div className="content-card">Current Balance: {formatNumber(amountBscs, 4)}</div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${bnbBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                            <img src="./images/img/check-before.png" alt="" />
                                            <img src="./images/img/check-after.png" alt="" />

                                        </div>
                                        <div className="title-card">BNB available in wallet</div>
                                        <div className="content-card">BNB is required to pay transaction fees on the BNB Smart Chain network.<br />BNB Balance: {formatNumber(bnbBalance, 4)}</div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${unstakeAmount <= 0 && account ? 'check-card acive' : 'check-card'}`}>
                                            <img src="./images/img/check-before.png" alt="" />
                                            <img src="./images/img/check-after.png" alt="" />

                                        </div>
                                        <div className="title-card">Eligible to stake</div>
                                        <div className="content-card">You cannot stake if you have an active BSCS unstake/withdrawal request</div>
                                    </div>
                                </div>
                                <div className="check-real" onClick={onChangeAll}>
                                    <Checkbox checked={isCheck} onChange={onChange} /> <span >I have read the <a target="_blank" href="/TermOfUse" className="terms">Terms and Conditions</a></span>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Amount to Stake</div>
                                        <div className="des">Stake BSCS</div>
                                        <div className="content-input">
                                            <div className="amount-stake-title">
                                                <div className="title-left">Staking amount</div>
                                                <div className="title-right"><span>{amountBscs}</span> BSCS available</div>
                                            </div>
                                            <div className="input-amount">
                                                <input
                                                    onChange={handleChangeInputStake}
                                                    value={amountStake}
                                                    className="amount-stake"

                                                // type="text"
                                                />
                                                <div className="posi">
                                                    <span className="palce-token">BSCS</span>
                                                    <button className="btn-max-stake" onClick={handleAmountMax}>Max</button>
                                                </div>

                                            </div>
                                            <div className="des-amount">
                                                <div className="staking-rule">Staking rules:</div>
                                                <ul>
                                                    <li>After 72 hours from staking, users can unstake, and the amount is at their discretion.</li>
                                                    <li>After unstaking, there is a 7-day waiting period before users can claim both the principal and the interest.</li>
                                                    <li>Users are not limited in the number of times and the quantity of BSCS they can stake; they can stake in multiple different batches.</li>
                                                    <li> Users cannot stake more if they have an unstake that is still within the 7-day lock period or if the 7-day lock period has ended but they have not yet withdrawn their funds.</li>
                                                </ul>
                                            </div>
                                            <div className="check-rule" onClick={onChangeRuleAll}>
                                                <Checkbox checked={isCheckRule} onChange={onChangeRule} />
                                                <span className="check-rule text">I understand the staking rules.</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step3">
                                                <div className="title">Pre-authorization</div>
                                                <div className="des">Required transaction 1 of 2</div>
                                                <div className="autho-content">
                                                    <div className="autho-title">In this step, you grant access to the staking smart contract to accept your BSCS</div>
                                                    <div className="autho-progress">
                                                        <Progress
                                                            strokeColor={{
                                                                "0%": "rgb(249 208 100 / 89%)",
                                                                "100%": "#F9D064",
                                                            }}
                                                            percent={50}
                                                            status="active"
                                                        />
                                                    </div>
                                                    <div className="autho-please">Please wait for the transaction to confirm before proceeding.</div>
                                                    {isMobile ? (
                                                        <a className="autho-link" rel="noreferrer" target="_blank" href={`https://bscscan.com/address/${account}`}>
                                                            {account ? (
                                                                <>
                                                                    {account.slice(0, 10)}...{account.slice(-10)}
                                                                </>
                                                            ) : ''}
                                                        </a>
                                                    ) : (
                                                        <a className="autho-link" rel="noreferrer" target="_blank" href={`https://bscscan.com/address/${account}`}>{account ? account : ''}</a>
                                                    )}

                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm</div>
                                                        <div className="des">Required transaction 2 of 2</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you deposit the tokens into the staking contract.</div>
                                                            <div className="cf-content1">After this step, your tokens will be successfully staked.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">Congratulations! Your tokens are now staked.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img src="./images/img/stake-success.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Success</div>
                                                                    <div className="mid-content1">Congratulations! Your tokens are now staked.</div>
                                                                    <div className="mid-content2">If desired, you may check BNB Smart Chain to confirm the transaction.</div>
                                                                    {isMobile ? (
                                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://bscscan.com/tx/${hashStake}`}>
                                                                            {hashStake.slice(0, 10)}...{hashStake.slice(-10)}
                                                                        </a>
                                                                    ) : (
                                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://bscscan.com/tx/${hashStake}`}>{hashStake}</a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
                <Action isSuccess={isSuccess} tabBox={props.tabBox} parentCallback={callbackFunction} />

            </div>
            <div className="steps-action">

                {current > 0 && current !== steps.length - 1 && (
                    <button className="btn-control" onClick={() => prev()}> Previous</button>
                )}
                {current < steps.length - 1 && (
                    <>
                        {showNext ? (
                            <button className="btn-control" onClick={() => next()}> Next</button>
                        ) : (
                            <button className="btn-control dis"> Next</button>
                        )}
                    </>
                )}
                {current === steps.length - 1 && (
                    <button className="btn-control" onClick={() => window.location.reload()}> Done</button>
                )}

            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title={current === 2 ? "Approving" : "Staking"}
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
            {/* <Button type="primary" onClick={showModal}>
                Open Modal
            </Button> */}
        </div>


    )
}

export default Stake;