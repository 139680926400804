/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { message } from "antd";
import Value from "../../../components/Value";
import { NETWORK } from "../index.d";

const TopIdoDetail = (props: any) => {
  const data = props.dataTop;
  const ether = (
    <>
      <img src="/images/imgido/ether.png" alt="" />
    </>
  );
  const poly = (
    <>
      <img src="/images/imgido/poly.png" alt="" />
    </>
  );
  const bsc = (
    <>
      <img src="/images/imgido/icon-bnb-ido.png" alt="" />
    </>
  );

  const fillIconChain = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return bsc;
        break;
      case NETWORK.Poly:
        return poly;
        break;
      case NETWORK.Ethereum:
        return ether;
        break;
    }
  };

  const fillNetwork = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return "Binance Smart Chain";
        break;
      case NETWORK.Poly:
        return "Polygon";
        break;
      case NETWORK.Ethereum:
        return "Ethereum";
        break;
      case NETWORK.Aptos:
        return "Aptos";
        break;
    }
  };

  let navigate = useNavigate();
  const handleCallClick = (symbol: any) => {
    navigate(`${"/Ido/FullSearchProject"}?${symbol}`);
  };

  const des = data?.description;

  return (
    <>
      <div className="main-ido-details">
        <div className="columns">
          <div className="colum w-50">
            <div className="content-ido-details">
              <div className="info-project-l">
                <div className="box-icon">
                  <img src={data.logoUrl} alt="" />
                </div>
                <div className="social-project">
                  <div className="name-project">
                    {data?.name} <span className="token">{data?.unit}</span>
                  </div>
                  <div className="social-name">
                    <div className="box-icon-sc">
                      <a
                        className="linkMedia"
                        href={data?.socical?.website}
                        target="blank"
                      >
                        <img src="/images/imgido/web.png" alt="" />
                      </a>

                      <a
                        className="linkMedia"
                        href={data?.socical?.telegram}
                        target="blank"
                      >
                        <img src="/images/imgido/tele-1.png" alt="" />
                      </a>

                      <a
                        className="linkMedia"
                        href={data?.socical?.twitter}
                        target="blank"
                      >
                        <img src="/images/imgido/twi-1.png" alt="" />
                      </a>

                      <a
                        className="linkMedia"
                        href={data?.socical?.medium}
                        target="blank"
                      >
                        <img src="/images/imgido/me-1.png" alt="" />
                      </a>
                    </div>
                    <div className="box-address">
                      {data?.idoContract && data?.idoContract === "TBA" ? (
                        "TBA"
                      ) : (
                        <>
                          {data?.idoContract?.slice(0, 5)}...
                          {data?.idoContract?.slice(-5)}
                        </>
                      )}
                      {/* {data?.idoContract === "TBA"
                        ? "TBA"
                        : data?.idoContract &&
                          `${data?.idoContract.substring(
                            0,
                            5
                          )}...${data?.idoContract.substring(
                            10,
                            data?.idoContract.length
                          )}`} */}

                      {data?.idoContract === "TBA" ? (
                        ""
                      ) : (
                        <CopyToClipboard
                          text={data?.idoContract}
                          onCopy={() => {
                            message.success({
                              type: "success",
                              content: "Copied!!",
                              className: "custom-class",
                              duration: 2,
                            });
                          }}
                        >
                          <span className="img-copy">
                            <img
                              className="coppy"
                              src="/images/imgido/copy.png"
                              alt=""
                            />
                          </span>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <p className="desc">{data?.description}</p>
              {data.isFullResearch ? (
                <div className="full-search">
                  <button
                    type="button"
                    className="btn-search-project"
                    onClick={() => handleCallClick(data?.symbol)}
                  >
                    Full Research Project {">>"}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="colum w-50">
            <div className="content-ido-details">
              <div className="info-project-r">
                <ul className="list-token-info">
                  <li className="name-color">Token Info</li>
                  <li>
                    <span className="text-l">Symbol:</span>
                    <span className="text-r">
                      <span className="icon-r">
                        <img src={data?.logoUrl} alt="" />
                      </span>
                      {data?.symbol}
                    </span>
                  </li>
                  <li>
                    <span className="text-l">Network:</span>
                    <span className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(data?.network)}
                      </span>
                      {fillNetwork(data?.network)}
                    </span>
                  </li>
                </ul>
                <ul className="list-token-info">
                  <li>
                    <span className="text-l">Swap Rate:</span>
                    <span className="text-r">{data?.swapAmount}</span>
                  </li>
                  <li>
                    <span className="text-l">IDO Supply:</span>
                    <span className="text-r">
                      <Value value={data?.idoSupply} />
                      {data?.symbol}
                    </span>
                  </li>
                  <li>
                    <span className="text-l">Total Supply:</span>
                    <span className="text-r">
                      <Value value={data?.totalSupply} />
                      {data?.symbol}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TopIdoDetail;
