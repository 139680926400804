/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Store } from "react-notifications-component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import { message, Empty, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useHookIDO } from "../StoreIdo";
import { NETWORK, CHAIN } from "../index.d";
import moment from "moment";
import {
  getProgressTime,
  rendererCountDown,
  fillNetwork,
  fillIconChain,
} from "../utilsIDO";

const ListUpcoming = () => {
  const [state, actions]: any = useHookIDO();
  const [classLoading, setClassLoading] = useState("loading");

  const date = new Date();
  const monthCurent = date.getMonth() + 1;
  const yearCurent = date.getFullYear();

  useEffect(() => {
    actions.getListUpComing(monthCurent, yearCurent);
    setTimeout(() => {
      setClassLoading("");
    }, 200);
  }, []);

  let navigate = useNavigate();
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const handleCallClick = (symbol: any) => {
    navigate(`${"/Ido/FullSearchProject"}?${symbol}`);
  };
  const handleCallClickDetails = (network: any, symbol: any, isOldIDo: any) => {
    if (network === CHAIN.APTOS) {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
      // if (isMobile) {
      //   showConfirm();
      // } else {
      //   navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
      // }
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else {
      navigate(`${"/Ido/Details"}?${symbol}`);
    }
  };

  const Item = (ido: any) => {
    const item = ido.ido;
    let timeUpcoming = moment(item?.idoStartTime * 1000).format(
      "MMM DD YYYY h:mm A"
    );

    const formatNumber = (x: any, max: any) => {
      if (x) {
        return x.toLocaleString("en-US", {
          minimumFractionDigits: 1,
          maximumFractionDigits: max,
        });
      } else return 0;
    };

    return (
      <>
        <div className="colum w-33 bage-custom">
          {item?.idoTrust !== "" ?
            <>
              {item.idoTrust === "S" ? (
                <div className="bage-s">Shielded</div>
              ) : item.idoTrust === "R" ? (
                <div className="bage-r">Riskless</div>
              ) : (
                <div className="bage-d">{item.idoTrust}</div>
              )}
            </>
            :
            <>
              {""}
            </>
          }

          <div className={`content-up ${classLoading}`}>
            <div className="box-content-top">
              <div className="box-img">
                <img src={item?.backgroundUrl} alt="background" />
              </div>
            </div>
            <div className="box-content-bottom">
              <div className="guide-swap">
                <div className="name-token">
                  {item?.name} <span className="symbol">{item?.unit}</span>
                </div>
                <p className="desc">{item?.description?.substring(0, 50)}...</p>
                <ul className="list-swap">
                  <li>
                    <span className="text-l">Swap Rate:</span>
                    <span className="text-r">{item?.swapAmount}</span>
                  </li>
                  <li>
                    <span className="text-l">Total Raise:</span>
                    <span className="text-r">
                      {formatNumber(item?.idoSupply * item?.pricePer, 1)} {item?.symbolUSD}
                      {/* {item?.idoSupply.toLocaleString()} {item?.symbol} */}
                    </span>
                  </li>
                  <li>
                    <span className="text-l">Total Supply:</span>
                    <span className="text-r">
                      {formatNumber(item?.totalSupply, 1)} {item?.symbol}
                      {/* {item?.totalSupply.toLocaleString()} {item?.symbol} */}
                    </span>
                  </li>
                </ul>
                <div className="list-socail">
                  <div className="text-item">
                    <div className="text-l">Join Network:</div>
                    <div className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(item?.network)}
                      </span>
                      {fillNetwork(item?.network)}
                    </div>
                  </div>
                  <div className="text-item">
                    <div className="text-l">Token Network:</div>
                    <div className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(item?.tokenNetwork)}
                      </span>
                      {fillNetwork(item?.tokenNetwork)}
                    </div>
                  </div>
                  <div className="text-item">
                    <div className="text-l">Community:</div>
                    <div className="text-r">
                      <div className="box-icon-sc">
                        <a
                          className="linkMedia"
                          href={item?.socical?.website}
                          target="blank"
                        >
                          <img src="/images/imgido/web.png" alt="" />
                        </a>

                        <a
                          className="linkMedia"
                          href={item?.socical?.telegram}
                          target="blank"
                        >
                          <img src="/images/imgido/tele-1.png" alt="" />
                        </a>

                        <a
                          className="linkMedia"
                          href={item?.socical?.twitter}
                          target="blank"
                        >
                          <img src="/images/imgido/twi-1.png" alt="" />
                        </a>

                        <a
                          className="linkMedia"
                          href={item?.socical?.medium}
                          target="blank"
                        >
                          <img src="/images/imgido/me-1.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="text-item">
                    <div className="text-l">Contract:</div>
                    <div className="text-r">
                      <span>
                        {item?.idoContract === "TBA" ? (
                          "TBA"
                        ) : (
                          <>
                            {item?.network === "apt"
                              ? item?.idoContract &&
                              `${item?.idoContract.substring(
                                0,
                                10
                              )}...${item?.idoContract.substring(
                                81,
                                item?.idoContract.length
                              )}`
                              : item?.idoContract &&
                              `${item?.idoContract.substring(
                                0,
                                10
                              )}...${item?.idoContract.substring(
                                37,
                                item?.idoContract.length
                              )}`}
                          </>
                        )}
                      </span>
                      {item?.idoContract === "TBA" ? (
                        ""
                      ) : (
                        <CopyToClipboard
                          text={item?.idoContract}
                          onCopy={() => {
                            message.success("Copied", 0.4);
                          }}
                        >
                          <span className="img-copy">
                            <img
                              className="coppy"
                              src="/images/imgido/copy.png"
                              alt=""
                            />
                          </span>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                  <div className="text-item">
                    <div className="text-l">Start Time:</div>
                    <div className="text-r">
                      <span className="times-join">
                        {item?.idoStartTime > 0 ? (
                          <>
                            <Countdown
                              date={timeUpcoming}
                              renderer={rendererCountDown}
                            />
                          </>
                        ) : (
                          <>N/A</>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="button-view">
                  <button
                    className="btn btn-view"
                    type="button"
                    onClick={() =>
                      handleCallClickDetails(
                        item?.network,
                        item?.symbol,
                        item?.isOldIDo
                      )
                    }
                  >
                    View Pool
                  </button>
                </div>
                {item?.isFullResearch === true ? (
                  <>
                    <div className="full-search">
                      <button
                        type="button"
                        className="btn-search-project"
                        onClick={() => handleCallClick(item?.symbol)}
                      >
                        Full Research Project {">>"}
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="main-list-upcoming">
        <div className="columns">
          {state.listUpComing?.length === 0
            ? ""
            : // <Empty style={{ paddingTop: "20px" }} />
            state.listUpComing.map((item: any, i: any) => (
              <Item ido={item} />
            ))}

          <div className="colum w-33 bage-custom">
            <img src="/images/item-com.png" alt="" />
            <div className="count-coming">
              <div className="wrap-count">
                <div className="count-title">Upcoming</div>
                <div className="count-num">
                  <div className="count-num-text">
                    {state.totalCount} projects
                  </div>
                  <div className="count-num-img">
                    <img src="./images/add-circle.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListUpcoming;
