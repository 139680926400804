/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Countdown from "react-countdown";
import { Empty, message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Value from "./../../../components/Value";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHookIDO } from "../StoreIdo";
import { NETWORK, CHAIN } from "../index.d";
import { fillNetwork, fillIconChain } from "../utilsIDO";
import { getProgressTime, rendererCountDown } from "../utilsIDO";

const ListIdoActive = () => {
    const [state, actions]: any = useHookIDO();

    const [classLoading, setClassLoading] = useState("loading");

    useEffect(() => {
        actions.getListActive({ category: 0 });
        setTimeout(() => {
            setClassLoading("");
        }, 200);
    }, []);

    let navigate = useNavigate();

    const { confirm } = Modal;

    const showConfirm = () => {
        confirm({
            className: "modal-not-sp",
            title: "Project not suport on Mobile",
            icon: <ExclamationCircleFilled />,
            content: "Please switch to another device.",
        });
    };

    const handleCallClick = (symbol: any) => {
        navigate(`${"/Ido/FullSearchProject"}?${symbol}`);
    };

    const handleCallClickDetails = (network: any, symbol: any, isOldIDo: any) => {
        if (network === CHAIN.APTOS) {
            navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
            // if (isMobile) {
            //   showConfirm();
            // } else {
            //   navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
            // }
        } else if (network === CHAIN.SOLANA) {
            navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
        } else if (isOldIDo) {
            navigate(`${"/Ido/IdoDetails"}?${symbol}`);
        } else {
            navigate(`${"/Ido/Details"}?${symbol}`);
        }
    };

    const Item = (item: any) => {
        const ido = item.ido;

        return (
            <>
                <div className="colum w-33 bage-custom">
                    {ido.idoTrust !== "" ?
                        <>
                            {ido.idoTrust === "S" ? (
                                <div className="bage-s">Shielded</div>
                            ) : ido.idoTrust === "R" ? (
                                <div className="bage-r">Riskless</div>
                            ) : (
                                <div className="bage-d">{ido.idoTrust}</div>
                            )}
                        </>
                        :
                        <>
                            {""}
                        </>
                    }

                    <div className={`content-up ${classLoading}`}>
                        <div className="box-content-top">
                            <div className="box-img">
                                <img src={ido?.backgroundUrl} alt="" />
                            </div>
                        </div>
                        <div className="box-content-bottom">
                            <div className="guide-swap">
                                <div className="name-token">
                                    {ido?.name} <span className="symbol"> {ido?.unit}</span>
                                </div>
                                <p className="desc">{ido?.description?.substring(0, 50)}...</p>
                                <ul className="list-swap">
                                    <li>
                                        <span className="text-l">Swap Rate:</span>
                                        <span className="text-r">{ido?.swapAmount}</span>
                                    </li>
                                    <li>
                                        <span className="text-l">Total Raise:</span>
                                        <span className="text-r d-flex">
                                            <Value decimals={0} value={ido?.idoSupply * ido?.pricePer} />
                                            {ido?.symbolUSD}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="text-l">Total Supply:</span>
                                        <span className="text-r d-flex">
                                            <Value decimals={0} value={ido?.totalSupply} />
                                            {ido?.symbol}
                                        </span>
                                    </li>
                                </ul>
                                <div className="list-socail">
                                    <div className="text-item">
                                        <div className="text-l">Join Network:</div>
                                        <div className="text-r">
                                            <span className="icon-symbol">
                                                {fillIconChain(ido?.network)}
                                            </span>
                                            {fillNetwork(ido?.network)}
                                        </div>
                                    </div>
                                    <div className="text-item">
                                        <div className="text-l">Token Network:</div>
                                        <div className="text-r">
                                            <span className="icon-symbol">
                                                {fillIconChain(ido?.tokenNetwork)}
                                            </span>
                                            {fillNetwork(ido?.tokenNetwork)}
                                        </div>
                                    </div>
                                    {/* <div className="text-item">
                    <div className="text-l">Network:</div>
                    <div className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(ido?.network)}
                      </span>
                      {fillNetwork(ido?.network)}
                    </div>
                  </div> */}
                                    <div className="text-item">
                                        <div className="text-l">Community:</div>
                                        <div className="text-r">
                                            <div className="box-icon-sc">
                                                <a
                                                    className="linkMedia"
                                                    href={ido?.socical?.website}
                                                    target="blank"
                                                >
                                                    <img src="/images/imgido/web.png" alt="" />
                                                </a>

                                                <a
                                                    className="linkMedia"
                                                    href={ido?.socical?.telegram}
                                                    target="blank"
                                                >
                                                    <img src="/images/imgido/tele-1.png" alt="" />
                                                </a>

                                                <a
                                                    className="linkMedia"
                                                    href={ido?.socical?.twitter}
                                                    target="blank"
                                                >
                                                    <img src="/images/imgido/twi-1.png" alt="" />
                                                </a>

                                                <a
                                                    className="linkMedia"
                                                    href={ido?.socical?.medium}
                                                    target="blank"
                                                >
                                                    <img src="/images/imgido/me-1.png" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-item">
                                        <div className="text-l">Contract:</div>
                                        <div className="text-r">
                                            {ido?.network === "apt" ? (
                                                <>
                                                    {ido?.idoContract &&
                                                        `${ido?.idoContract.substring(
                                                            0,
                                                            5
                                                        )}...${ido?.idoContract.substring(
                                                            85,
                                                            ido?.idoContract.length
                                                        )}`}
                                                </>
                                            ) : (
                                                <>
                                                    {ido?.idoContract &&
                                                        `${ido?.idoContract.substring(
                                                            0,
                                                            5
                                                        )}...${ido?.idoContract.substring(
                                                            81,
                                                            ido?.idoContract.length
                                                        )}`}
                                                </>
                                            )}

                                            {ido?.idoContract === "TBA" ? (
                                                ""
                                            ) : (
                                                <CopyToClipboard
                                                    text={ido?.idoContract}
                                                    onCopy={() => {
                                                        message.success({
                                                            type: "success",
                                                            content: "Copied!!",
                                                            className: "custom-class",
                                                            duration: 2,
                                                        });
                                                    }}
                                                >
                                                    <span className="img-copy">
                                                        <img
                                                            className="coppy"
                                                            src="/images/imgido/copy.png"
                                                            alt=""
                                                        />
                                                    </span>
                                                </CopyToClipboard>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-item">
                                        <div className="text-l">End Time:</div>
                                        <div className="text-r">
                                            {ido?.idoCloseTime === 0 ? (
                                                "TBA"
                                            ) : (
                                                <Countdown
                                                    date={ido?.idoCloseTime * 1000}
                                                    renderer={rendererCountDown}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="button-view">
                                    <button
                                        className="btn btn-view"
                                        type="button"
                                        onClick={() =>
                                            handleCallClickDetails(
                                                ido?.network,
                                                ido?.symbol,
                                                ido?.isOldIDo
                                            )
                                        }
                                    >
                                        View Pool
                                    </button>
                                </div>
                                {ido?.isFullResearch === true ? (
                                    <>
                                        <div className="full-search">
                                            <button
                                                type="button"
                                                className="btn-search-project"
                                                onClick={() => handleCallClick(ido?.symbol)}
                                            >
                                                Full Research Project {">>"}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <div className="main-list-upcoming">
                <div className="columns">
                    {state.listActive?.length === 0 ? (
                        <Empty style={{ paddingTop: "20px" }} />
                    ) : (
                        state.listActive &&
                        state.listActive.map((item: any, i: any) => <Item ido={item} />)
                    )}
                </div>
            </div>
        </>
    );
};
export default ListIdoActive;
